import React from 'react'
import './About_Fourth.css'
import pic1 from './logo2.jpeg'
import pic2 from './2dbgr.png'
import pic3 from './3D.jpg'
function About_Fourth() {
  return (
    <div>
      <div className='About_fourth_main_div'>
        <h1 className='about_fourth_heading'>Body of work</h1>
        <div className='about_fourth_div'>
          <div className='about_fourth_div1'>
            <h3 className='about_fourth_h3'>2019-2020</h3>
            {/* <h2 className='about_fourth_h2'> Logo Design</h2> */}
            <div className='About_fourth_img_div'>
            <img className='about_fourth_pic' src={pic1} alt='images'></img>
            </div>
            {/* <button class="button-52" role="button">Button 52</button> */}
            <a href='https://www.behance.net/tarishiagrawal' target='_blank'><button class="button2">Explore</button></a>
            <p className='about_fourth_para1'>During my learning stage, I explored visual identity through logo design and motion graphics, enhancing my appreciation for images and laying the foundation for my future creative career.</p>

          </div>

          <div className='about_fourth_div1'>
            <h3 className='about_fourth_h3'>2021-2022</h3>
            {/* <h2 className='about_fourth_h2'>The Midnight Enigma</h2> */}
            <div className='About_fourth_img_div'>
            <img className='about_fourth_pic1' src={pic2} alt='images'></img>
            </div>
            <a href='https://www.behance.net/tarishiagrawal' target='_blank'><button class="button2">Explore</button></a>
            
            <p className='about_fourth_para1'>Worked in 2D animation projects excelled in  logo design, and motion graphics, blending traditional and modern techniques to create dynamic, expressive visual narratives.</p>
          </div>

          <div className='about_fourth_div1'>
            <h3 className='about_fourth_h3'>2023</h3>
            {/* <h2 className='about_fourth_h2'>  Shadows of Deceit</h2> */}
            <div className='About_fourth_img_div'>
            <img className='about_fourth_pic' src={pic3} alt='images'></img>
            </div>
            <a href='https://www.behance.net/tarishiagrawal' target='_blank'><button class="button2">Explore</button></a>
            
            <p className='about_fourth_para1'>Exploring 3D world through animation, modeling, and logo creation, I expanded my understanding of visual storytelling and made significant progress in my creative journey.</p>
          </div>

        </div>
      </div>
    </div>
  )
}

export default About_Fourth