import React from 'react'
import './About_third.css'
function About_third() {
    return (
        <div>
            <div className='About_third_main_div'>
                <div className='about_third_div1'>
                    <h1 className='about_third_heading'>Education</h1>
                    <p className='about_third_para'> Tarishi Agrawal's academic background
                        has given her a deep understanding of
                        animation and graphic design, enabling
                        her to create dynamic graphics and bring
                        concepts to life on screen. Her
                        scholastic accomplishments and artistic
                        endeavors demonstrate her commitment
                        to perfecting the art of visual storytelling,
                        which distinguishes her in the field of
                        digital media.</p>
                </div>
                <div className='about_third_div2'>
                    <div>
                        
                        <h2 className='about_third_heading22'>Bachelor of Science</h2>
                        <p className='about_third_paragraph'>Pt. Ravishankar Shukla University, Raipur Chhattisgarh, India
                            Concentration: Science.
                        </p>
                        <hr className='about_third_hr'></hr><br/>
                    </div>
                    <div>
                        <h2 className='about_third_heading2'>Bachelor of Arts Graphic Design And Animation</h2>
                        <p  className='about_third_paragraph'>University of Hertfordshire, England
                            Specialization: Thriller Filmmaking & Cinematic Atmosphere
                            Honors: Graduated with Distinction for Outstanding Directorial
                            Achievements.
                        </p><br/>
                        <hr className='about_third_hr'></hr><br/>
                    </div>
                    <div>
                        <h2 className='about_third_heading2'>Maters of Arts In Creature And Character Animation </h2>
                        <p  className='about_third_paragraph'>Obscura Film Institute, London
                            Focused on the psychological aspects of storytelling, delving into the art
                            of suspense, subtext, and building tension.</p><br/>
                        <hr className='about_third_hr'></hr><br/>
                    </div>
                    <div>
                        <h2 className='about_third_heading2'>Short Course in Digital Marketing And cinematic film editing</h2>
                        <p  className='about_third_paragraph'>Whisper Studios, San Francisco
                            Studied the art of creating immersive soundscapes to enhance the
                            atmospheric quality of thriller films.
                        </p><br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About_third