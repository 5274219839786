import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeartAnimation from './HeartAnimation';
import lion from '../Images/Lion1.png';
import './HomePageTesting.css';
const HomePage = () => {
    const navigate = useNavigate();

    const handleButtonClick = (path) => {
        document.querySelector('.heart').classList.add('animate');
        setTimeout(() => {
            document.querySelector('.heart').classList.remove('animate');
            navigate(path);
        }, 1000); // Adjusted timeout to match the animation duration
    };

    return (
        <div className="home-page">
            <div className='social-container-div'>
         
            </div>
            <div className="lion-container">
                <img src={lion} alt="Lion" className="lion" 
                // onClick={() => handleButtonClick('/main')}
                />
                <HeartAnimation />
            </div>
            <div className="buttons-container">
                <div className="left-buttons">
                    <button className='Home_Button1'onClick={() => handleButtonClick('/about')}>ABOUT</button><br/><br/>
                    <button className='Home_Button2' onClick={() => handleButtonClick('/admission')}>ADMISSION</button><br/><br/>
                    <button className='Home_Button9' onClick={() => handleButtonClick('/Gallery')}>WORK GALLERY</button>
                    <button className='Home_Button3' onClick={() => handleButtonClick('/banner')}>BANNERS</button>
                    <button className='Home_Button8' onClick={() => handleButtonClick('/poster')}>POSTER</button>
                    

                </div>
                <div className="right-buttons">
                    <button className='Home_Button4' onClick={() => handleButtonClick('/comics')}>COMICS</button><br/>
                    <button className='Home_Button5' onClick={() => handleButtonClick('/videos')}>VIDEOS</button><br/>
                    <button className='Home_Button6' onClick={() => handleButtonClick('/motion')}>MOTION GRAPHICS</button><br/> 
                    <button className='Home_Button7' onClick={() => handleButtonClick('/digital')}>DIGITAL ARTS</button>
                    
                </div>
            </div>
        </div>
    );
};

export default HomePage;