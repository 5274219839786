import React from 'react'
import './About_second.css'
import girl from './Tarishi@.jpeg'
import { Link } from 'react-router-dom';

function About_second() {
  return (
    <div>
      <div className='About_Second_main_div'>
        <div className='About_second_first_div'>
          <img className='about_second_image' src={girl}></img>
        </div>
        <div className='About_second_second_div'>
          <div className='second_main1'>
            <div className='second_main1_div'>BIO</div>
            <div className='second_main1_div2'>
              <p className='content_para1'>Tarishi Agrawal is a visionary designer who hails
                from Raipur, Chhattisgarh. She has a unique talent
                for animation and graphic design. She is renowned
                for stretching the bounds of visual storytelling and
                for eloquently capturing the spirit of creativity in
                ways that audiences find deeply moving. Tarishi
                stands out in the realm of digital media because of
                her natural ability to combine innovation and
                tradition to create gripping storylines that immerse
                viewers in her imaginative universe.
              </p>
            </div>
          </div><br /><br /> 
          <div className='second_main2'>
            <div className='second_main1_div'>MY APPROACH </div>
            <div className='second_main1_div2'>
              <p className='content_para1'>I have always been fascinated by the magic of
                visual storytelling and the transformative power of
                art. My approach to creating impactful designs and
                animations is fueled by a passion for turning ideas
                into vibrant realities that captivate and inspire. By
                blending creativity with precision, I strive to craft
                visual experiences that resonate deeply and leave
                a lasting impression. I look forward to continuing
                to push the boundaries of design and animation,
                bringing fresh, innovative visions to life.
              </p>
            </div>
          </div>
          <div className='button_div1'>
       
          <Link to="/contact"><button class="button-84" role="button">CONNECT</button></Link>
           <button class="animated-button">
            <svg xmlns="http://www.w3.org/2000/svg" class="arr-2" viewBox="0 0 24 24">
              <path
                d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
              ></path>
            </svg>
            <span class="text"><a href="https://www.behance.net/tarishiagrawal" target='_blank' id='DISCOVER'>DISCOVER</a></span>
            <span class="circle"></span>
            <svg xmlns="http://www.w3.org/2000/svg" class="arr-1" viewBox="0 0 24 24">
              <path
                d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
              ></path>
            </svg>
          </button> 
          </div>
        </div>
      </div>


    </div>
  )
}

export default About_second