import React from 'react'
import './About1.css';
import About_second from '../About/About_second'
import About_third from '../About/About_third'
import About_Fourth from './About_Fourth';
import Testimonials from './Testimonials'
import Footer from '../Footer/Footer'
import success from './SUCCESS.jpg'
import TopNavbar from '../Nav/TopNavbar'
import { Link } from 'react-router-dom';
function About() {
    return (
        <div>
            <br />
            <div className="dhyey_header" style={{ backgroundColor: "white" }}>
                <TopNavbar />
            </div>
            <br />  <br />
            <br />


            <div className='About_first_main_div'>
                <div className='about_first_div'>
                    <h2 className='about_heading_h2'>Step Into Design <br />
                        World Of Dhyey</h2>
                    <p className='about_firstpage_div_paragraph'>Our mission is to inspire and educate individuals in graphic
                        design, comic art, digital art, video editing, and motion
                        graphics, focusing on creativity and technical expertise.<br /><br />
                        Dhyey Academy, founded on art and technology, fosters a
                        community of students and educators dedicated to artistic
                        excellence, preparing them for the digital age.<br /><br />
                        Our values include creativity, excellence, community,
                        honesty, and professionalism, aiming to set high standards
                        in education, create an inclusive environment, and uphold
                        integrity in all our endeavors.
                    </p>
                    <div className='Aboutbtn'>
                        <Link to='/contact'> <button class="button-89" role="button">Let's Talk</button></Link>
                    </div>
                </div>
                <div className='about_first_div2'>
                    <img className='about_first_div2_image' src={success}></img>
                </div>

            </div>
<br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <About_second />
            <About_third />
            <About_Fourth />
            <Testimonials />

            <Footer />
        </div>
    )
}

export default About