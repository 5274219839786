import React, { useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import CloseIcon from "./CloseIcon";
import { HomeOutlined, ContactsOutlined } from '@ant-design/icons';
import logo from "../Images/1.png";
import { IoIosContact } from "react-icons/io";
import { SlUser } from "react-icons/sl";
import { SlGraduation } from "react-icons/sl";
import { AiFillProduct } from "react-icons/ai";
import { LiaUniversitySolid } from "react-icons/lia";
import { FiLink2 } from "react-icons/fi";
export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const [showInsightMenu, setShowInsightMenu] = useState(false); // State to control Insight submenu

  // Function to toggle Insight submenu
  const toggleInsightMenu = () => {
    setShowInsightMenu((prev) => !prev);
  };

  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen} >
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          {/* <LogoIcon /> */}
          <img src={logo} alt="logo" width={50} />
          <h1 className="whiteColor font20" style={{ marginLeft: "15px", marginTop: "10px" }}>
            Dhyey Academy
          </h1>


        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer" >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
  <NavLink to="/" exact className="headerlink1" activeClassName="active" style={{ fontFamily: "Montserrat, sans-serif", color: "white", fontWeight: "600", fontSize: "14px" }}>
    <HomeOutlined /> &nbsp; Home
  </NavLink>
  <NavLink to="/about" className="headerlink2" activeClassName="active" style={{ fontFamily: "Montserrat, sans-serif", color: "white", fontWeight: "600", fontSize: "14px" }}>
    <SlUser /> &nbsp; About
  </NavLink>
  <NavLink to="/courses" className="headerlink3" activeClassName="active" style={{ fontFamily: "Montserrat, sans-serif", color: "white", fontWeight: "600", fontSize: "14px" }}>
    <SlGraduation /> &nbsp; Courses
  </NavLink>
  <NavLink to="/services" className="headerlink4" activeClassName="active" style={{ fontFamily: "Montserrat, sans-serif", color: "white", fontWeight: "600", fontSize: "14px" }}>
    <AiFillProduct /> &nbsp; Services
  </NavLink>
  <NavLink to="/admission" className="headerlink5" activeClassName="active" style={{ fontFamily: "Montserrat, sans-serif", color: "white", fontWeight: "600", fontSize: "14px" }}>
    <LiaUniversitySolid /> &nbsp; Admission
  </NavLink>
  <div className="insight-menu">
    <span onClick={toggleInsightMenu} className="insight-link" style={{ fontFamily: "Montserrat, sans-serif", color: "white", fontWeight: "600", fontSize: "14px" }}>
      <FiLink2 /> &nbsp; Insight
    </span>
    {showInsightMenu && (
      <div className="insight-submenu">
        <NavLink to="/Blogpost" className="headerlink6" activeClassName="active" style={{ fontFamily: "Montserrat, sans-serif", color: "black", fontWeight: "600", fontSize: "14px" }}>
          Blog
        </NavLink>
        <NavLink to="/news" className="headerlink7" activeClassName="active" style={{ fontFamily: "Montserrat, sans-serif", color: "black", fontWeight: "600", fontSize: "14px" }}>
          News & Events
        </NavLink>
      </div>
    )}
  </div>
  <NavLink to="/contact" className="headerlink8" activeClassName="active" style={{ fontFamily: "Montserrat, sans-serif", color: "white", fontWeight: "600", fontSize: "14px" }}>
    <IoIosContact /> &nbsp; Contact
  </NavLink>
</UlStyle>




    </Wrapper>
  );
}

const Wrapper = styled.nav`

  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
