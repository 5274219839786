import React from 'react';
import './Service.css';
import gif1 from './motion.gif'
import gif2 from './photo-book_15584199.gif'
import gif3 from './digital-art_15557703.gif'
import gif4 from './video-channel_9583349.gif'
// import gif5 from './properties_8801174.gif'
// import gif6 from './monitor_8722555.gif'
import gif7 from './computer_15584165.gif'
import gif8 from './digital-twin_15713129.gif'
// import gif9 from './vector_8800707.gif'
import Header from '../Header/Header.js'
import Footer from '../Footer/Footer.js';
import { Link } from 'react-router-dom';
import TopNavbar from '../Nav/TopNavbar'
const Services = () => {
  const services = [
    {
      title: "Motion Graphics",
      description: "Icon/Logo animation, Short animation, Explainer Video, Detailed animation, High-end production",
      icon: gif1, // You can replace this with an actual SVG or image
    },
    {
      title: "3D Modeling",
      description: "Chracter modeling, EnvirOnment modeling, Product modeling, 3D animation and rigging, 3D printing model, Concept art",
      icon: gif8,
    }
    ,
    {
      title: "Digital Art",
      description: "Concept art, character Design, Illustrations, Digital protraits, Bookcover, Game art, 3D art, Infographics, Storyboarding",
      icon: gif3,
    },
    {
      title: "Videos",
      description: "Documentory editing, Video editing, Corporate and promotional, social-media content, Event hightlight, Youtube video, Subtitle and captioning ",
      icon: gif4, // You can replace this with an actual SVG or image
    },
    {
      title: "Graphic Design",
      description: "Brand identity, Design, Packaging, Print, Advertising, Presentation, Portfolio , Icon , Logo design, Social-media graphics",
      icon: gif7,
    },
    {
      title: "Comics",
      description: "Simple art, Detailed art, Short comic,Full-length comic",
      icon: gif2,
    }
    ,

  ];

  return (
    <>
      <TopNavbar />
      <section className="services-section">
        <h1>Services</h1>
        <p className='Service-para'>
          At <b>Dhyey Academy's</b>  Graphic Design Studio, we pride ourselves on blending creativity
          with professionalism to deliver exceptional graphic design services. </p>
        <div className="services-container">
          {services.map((service, index) => (
            <div className="service-card" key={index}>
              <div className="service-icon"><img className="icon_service" src={service.icon} alt="News Icon" />
              </div>
              <h2>{service.title}</h2>
              <p>{service.description}</p>
              <Link to="/contact"><button className="know-more-btn" >Contact</button></Link>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Services;
