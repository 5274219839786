import React from 'react'
import TopNavbar from '../Nav/TopNavbar'
import '../Gallery/Gallery.css'
import Header from '../Header/Header.js'
import Footer from '../Footer/Footer.js';
import { Dhyeyposterget } from '../Api/CoreApi.js';
import { useEffect, useState } from 'react';
function Banner() {
    const [cardsData, setcardsData] = useState([])
    useEffect(() => {
        dhyeyposterget()

    }, [])
    const dhyeyposterget = async () => {
        const response = await Dhyeyposterget();
        setcardsData(response)
    }

    return (
        <>
          <TopNavbar />
      <section className="gallery-section">
        <h1>Poster</h1>
        <div className="gallery-container">
          {cardsData.map((blog, index) => (
            <div className="gallery-card" key={index}>
              <img className="gallery-image" src={blog.picture} alt={`Gallery Image ${index + 1}`} />
            </div>
          ))}
        </div>
        <br/>
      </section>
      <Footer />
        </>
    )
}

export default Banner
