import React from 'react';
import './FeeStructure.css';
// import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import TopNavbar from '../Nav/TopNavbar'
import FeeStructurepdf from './fee Structure.pdf'

const FeeStructurePage = () => {
  const courses = [
    { Sr: 1, type: 'Offline', Category: 'Beginner', Category1: 'Advance', duration: '2 Months', duration1: '6 Months', fee: '₹19,999',fee1: '₹39,999' },
    { Sr: 2, type: 'Online', Category: 'Beginner', Category1: 'Advance', duration: '2 Months',duration1: '6 Months', fee: '₹19,999',fee1: '₹39,999' },
  ];
  const courses1 = [
    { Sr: 3, type: 'Workshop',  duration: '2 Weeks', fee: '₹3000' },
    { Sr: 4, type: 'Short Term',  duration: '6 Weeks',fee: '₹7000',},
  ];
  
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = FeeStructurepdf; // Replace with the actual file path
    link.download = 'FeeStructure.pdf';
    link.click();
  };

  return (
    <div>
      <TopNavbar />
      <div className='fee_main_div'>
        <h1 className='feestructure-heading'>Dhyey Academy Fee Structure</h1>
        <div className="fee-structure-page">
          <table className="fee-table">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Course Type</th>
                <th>Category</th>
                <th>Duration</th>
                <th>Fees</th>
              </tr>
            </thead>
            <tbody>
              {courses.map((course, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td rowSpan="2">{course.Sr}</td>
                    <td rowSpan="2">{course.type}</td>
                    <td>{course.Category}</td>
                    <td>{course.duration}</td>
                    <td>{course.fee}</td>
                  </tr>
                  <tr>
                    <td>{course.Category1}</td>
                    <td>{course.duration1}</td>
                    <td>{course.fee1}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>

          <table className="fee-table">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Course Type</th>
                <th>Duration</th>
                <th>Fees</th>
              </tr>
            </thead>
            <tbody>
              {courses1.map((course, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td >{course.Sr}</td>
                    <td >{course.type}</td>
                    <td>{course.duration}</td>
                    <td>{course.fee}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>

          <button className="download-btn" onClick={handleDownload}>
            Download Fee Structure (PDF)
          </button>
        </div><br/>
      </div>
      <Footer />
    </div>
  );
};

export default FeeStructurePage;
