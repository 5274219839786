import React from 'react';
import './Testimonials.css';
import Mansi from './Testimonialimg/mansi.jpg';
import Syam from './Testimonialimg/Syam.jpeg'
import Khushi from './Testimonialimg/Khushi.jpeg'
import Jatin from './Testimonialimg/Jatin.jpg'

const testimonialsData = [
  {
    name: "Mansi Sahu",
    role: "Fashion designer",
    testimonial: "I've been learning Photoshop, Illustrator, and video editing at Dhyay Academy, and it has been an incredibly valuable experience. The instructors are knowledgeable and patient, making even complex tools easy to understand. The curriculum is hands-on, giving me practical skills that I can apply right away. The environment is supportive, and they provide personalized attention to help students improve. If you're looking to enhance your editing skills, Dhyay Academy is definitely a great choice!",
    image: Mansi // Replace with actual image URL
  },
  {
    name: "Syam M",
    role: "Graphic designer",
    testimonial: "Taking a course at Dhyey Academy has been a game-changer for me in graphic design. The lessons are clear, practical, and cover a wide range of essential design concepts. The hands-on approach helped me improve my skills quickly, and the instructors were always there to guide me. Whether you're a beginner or looking to refine your expertise, I highly recommend Dhyey Academy for anyone passionate about design.",
    image: Syam// Replace with actual image URL
  },
  {
    name: "Jatin vishwakarma",
    role: "Graphic designer",
    testimonial: "I was searching for academies specializing in printing but found none until a friend recommended Dhyey Academy. After a demo class, I knew it was exactly what I was looking for; they provided clear insights into the vast world of printing. It made me realize the dedication required to excel in this industry.",
    image: Jatin // Replace with actual image URL
  },
  {
    name: "Khushi Agrawal",
    role: "Visual designer",
    testimonial: "Dhyey Academy has been a game-changer for me. With expert guidance from Tarishi Agrawal, I mastered visual design and essential software skills to bring my creative ideas to life.",
    image: Khushi // Replace with actual image URL
  }
];

const Testimonials = () => {
  return (
    <div className="testimonials-container">
      <h1 className="testimonials-title">Testimonials</h1>
      <div className="testimonials-grid">
        {testimonialsData.map((item, index) => (
          <div className='gird-list'>
          <div className="testimonial-item" key={index}>
            <img src={item.image} alt={item.name} className="testimonial-image" />
            <div className="testimonial-text">
              <h3>{item.name}</h3>
              <p className="role">{item.role}</p>
              <p className="testimonial-quote">"{item.testimonial}"</p>
            </div>
          </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
