import React, { useState,useEffect } from 'react';
import './Gallery.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Dhyeyworkget } from '../Api/CoreApi';
import TopNavbar from '../Nav/TopNavbar'
const Gallery = () => {
  const [work,setWork] =useState([])
  useEffect(() => {
    dhyeyworkget()

}, [])
const dhyeyworkget = async () => {
    const response = await Dhyeyworkget();
    setWork(response)
}
  return (
    <>
      <TopNavbar />
      <section className="gallery-section">
        <h1>Work Gallery</h1>
        <div className="gallery-container">
          {work.map((blog, index) => (
            <div className="gallery-card" key={index}>
              <img className="gallery-image" src={blog.picture} alt={`Gallery Image ${index + 1}`} />
            </div>
          ))}
        </div>
        <br/>
      </section>
      <Footer />
    </>
  );
};

export default Gallery;
