
import React,{useRef} from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaArtstation } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { FloatingWhatsApp } from 'react-floating-whatsapp2';
import Logo from '../Images/1.png'
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import emailjs from '@emailjs/browser';


import { faFacebookF, faThreads, faInstagram, faLinkedinIn ,faBehance} from '@fortawesome/free-brands-svg-icons';

function Footer() {

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    alert()
    // service_hwyl2tn
    emailjs.sendForm('service_cwtzhfp', 'template_thxevnh', form.current, 'Ue1hcN6E29IqO7Sz8')
      .then((result) => {
        console.log(result)
        if (result.text === 'OK') {
          form.current.reset();
        }
      }, (error) => {
        console.log(error.text);
      });
  };
    return (
        <>
    


<div className='footer_container'>
  <div className='footer_section'>
    <p className='footer_paragraph'>SOCIAL MEDIA</p>
    <hr className='footer_hr'/>
  </div>

  <div className='social_icons'>
    <a href='https://www.instagram.com/dhyey_ac?utm_source=qr&igsh=MWd2YzZpMmcyZzF6cQ==' target='_blank' style={{textDecoration:"none",color:"white"}}><FontAwesomeIcon icon={faInstagram} /></a>
    <a href='https://www.facebook.com/dhyeyacademy.co.in' target='_blank' style={{textDecoration:"none",color:"white"}}><FontAwesomeIcon icon={faFacebookF} /></a>
    <a href='https://www.artstation.com/taru777' target='_blank' style={{textDecoration:"none",color:"white"}}><FaArtstation icon={faThreads} /></a>
    <a href='https://www.linkedin.com/in/tarishi-agrawal-a12235198?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app' target='_blank' style={{textDecoration:"none",color:"white"}}><FontAwesomeIcon icon={faLinkedinIn} /></a>
    <a href='https://www.behance.net/tarishiagrawal' target='_blank' style={{textDecoration:"none",color:"white"}}><FontAwesomeIcon icon={faBehance} className="icon behance-icon" /></a>
  </div>

  <div className='footer_section'>
   <p className='footer_paragraph'>CONNECT</p>
    <hr className='footer_hr1'/>
  </div>

  <div className='footer_section2'>
    <div className='footer_div1'>
      <p className='footer_div1_para'>Newsletter</p>
      <p className='footer_div1_paragraph'>Stay in the loop. Get the latest insights on growth and transformation.</p>
      <br/>
      <form ref={form} onSubmit={sendEmail}>
      <input placeholder='Enter Your Email' type='email' name='email' style={{padding:"10px",borderRadius:"15px",border:"none",width:"300px"}} required/>&nbsp;
      <button style={{backgroundColor:"purple",color:"white",padding:"10px",border:"none",borderRadius:"15px"}}
      id='SUBSCRIBE_BUTTON'
      >SUBSCRIBE</button>
      </form>
    </div>

    <div className='footer_div2'>
      <p className='footer_div1_para'>Contact Us</p>
      <p className='footer_div1_paragraph'>Need help with a project, have a question about employment or our work with nonprofits? We're here.</p>
      <Link to="/contact" style={{fontFamily:"Montserrat, sans-serif",textDecoration:"none"}}><a className='footer_a'>CONNECT</a></Link>
    </div>
  </div>

  <div className='footer_section'>
    <p className='footer_paragraph'>PROPHET 2024</p>
    <hr className='footer_hr'/>
  </div>

  <div className='footer_last_main_div'>
  <Link to="/services" style={{fontFamily:"Montserrat, sans-serif",textDecoration:"none",color:"white"}}><p>Services</p></Link>
  <Link to="/about" style={{fontFamily:"Montserrat, sans-serif",textDecoration:"none",color:"white"}}><p>About</p></Link>
  <Link to="/PrivacyPolicy" style={{fontFamily:"Montserrat, sans-serif",textDecoration:"none",color:"white"}}><p>Privacy Policy</p></Link>
    <Link to="/Gallery" style={{fontFamily:"Montserrat, sans-serif",textDecoration:"none",color:"white"}}><p>Work</p></Link>
    {/* <p>Aaker</p> */}
    <Link to="/TermsAndConditions" style={{fontFamily:"Montserrat, sans-serif",textDecoration:"none",color:"white"}}><p>Terms and Condition</p></Link>
    
    {/* <p>Thinking</p> */}
    {/* <p>Careers</p> */}
  </div><br/>
  <p> <FontAwesomeIcon icon={faBolt} /> <a href='https://www.yashvitech.com/' target='_blank' style={{textDecoration:"none",color:"white",textAlign:"left",fontSize:"12px"}}>Yashvitech IT Solution</a></p>
  <div></div>
</div>
<FloatingWhatsApp avatar={Logo} accountName='Dhyey Academy' notificationSound={true} phoneNumber='917024124442' />

          </>
    );
}

export default Footer;