// PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css'; // Import the CSS file for styling
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import TopNavbar from '../Nav/TopNavbar'
const PrivacyPolicy = () => {
    return (
        <div className='privacy-policy-main-div'>
        <TopNavbar/><br/>
        <h1 className="privacy-policy-title">Privacy Policy</h1>
        <div className="privacy-policy-container">
            
            <section className="privacy-policy-section">
                <h2 className="privacy-policy-heading">Introduction</h2>
                <p className="privacy-policy-text">
                    This Privacy Policy explains how Dhyey Academy collects, uses, and discloses information about students, visitors, and users of our services. By accessing or using our services, you agree to the collection and use of information in accordance with this policy.
                </p>
            </section>
            <section className="privacy-policy-section">
                <h2 className="privacy-policy-heading">Information We Collect</h2>
                <p className="privacy-policy-text">
                    We collect information that you provide to us directly, such as when you enroll in a course, fill out a form, or contact us. This information may include your name, email address, phone number, and other personal details.
                </p>
                <p className="privacy-policy-text">
                    We also collect information automatically as you navigate through our website. This may include usage details, IP addresses, and information collected through cookies and other tracking technologies.
                </p>
            </section>
            <section className="privacy-policy-section">
                <h2 className="privacy-policy-heading">How We Use Your Information</h2>
                <p className="privacy-policy-text">
                    We use the information we collect to provide, maintain, and improve our services. This includes processing your registration, communicating with you, and customizing your experience on our website.
                </p>
                <p className="privacy-policy-text">
                    We may also use your information for marketing purposes, such as sending you updates, promotions, and other information that may be of interest to you.
                </p>
            </section>
            <section className="privacy-policy-section">
                <h2 className="privacy-policy-heading">Sharing of Information</h2>
                <p className="privacy-policy-text">
                At Dhyey Academy, we respect your privacy and are committed to safeguarding your information. We only share your data with trusted third-party providers for essential services, comply with legal obligations, and protect your rights. Your information will not be used for marketing without your consent, ensuring a secure and positive experience with us.      
                We may also disclose information if required by law or in response to valid requests by public authorities (e.g., a court or government agency).
                </p>
            </section>
            <section className="privacy-policy-section">
                <h2 className="privacy-policy-heading">Your Choices and Rights</h2>
                <p className="privacy-policy-text">
                At Dhyey Academy, we value your privacy and provide you with control over your personal information. You have the right to access, update, or delete your data at any time. You can manage your preferences and opt out of any communications by following the instructions provided in our messages or by contacting us directly. We are committed to ensuring your information is handled with care and transparency, and we are here to support you in making informed choices about your privacy.
                </p>
            </section>
            <section className="privacy-policy-section">
                <h2 className="privacy-policy-heading">Security</h2>
                <p className="privacy-policy-text">
                At Dhyey Academy, your privacy and the security of your personal information are paramount.We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.                </p>
            </section>
            {/* <section className="privacy-policy-section">
                <h2 className="privacy-policy-heading">Changes to This Privacy Policy</h2>
                <p className="privacy-policy-text">
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                </p>
            </section> */}
            <section className="privacy-policy-section">
                <h2 className="privacy-policy-heading">Contact Us</h2>
                <p className="privacy-policy-text">
                    If you have any questions about this Privacy Policy, please contact us at:
                </p>
                <p className="privacy-policy-text">Email: support@dhyeyacademy.co.in</p>
                <p className="privacy-policy-text">Phone: +91-7024124442</p>
            </section>
        </div><br/>
        <Footer/>
        </div>
    );
};

export default PrivacyPolicy;
