import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from '../src/Home/HomePage.js';
import Admission from './Addmision/AdmissionForm.js';
import About from './About/About.js';
import Courses from './Courses/Courses.js'
import Services from './Services/Service'
import Contact from './Contact/Contact_us.js'
import Banner from './Banner/Banner'
import Comics from './Comics/Comics'
import Videos from './Video/Videos.js'
import Motion from './Motion/Motion.js'
import Digital from './Digital/Digital.js'
// import Insight from './Insight/Insight.js'
import OfflineCourses from './Courses/Offline_course.js'
import OnlineCourses from './Courses/Online_courses.js'
import Workshop from './Courses/Workshop.js'
import ShortCourses from './Courses/ShortTerm_courses.js'
import FeeStructure from './Courses/FeeStructure.js'
import Blogpost from './Insight/Blogpost.js'
import News from './Insight/News&Events.js'
import Poster from './Poster/Poster.js';
import Gallery from './Gallery/Gallery.js' 
import TermsAndConditions from './TermsAndConditions/TermsAndConditions.js';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy.js';
const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/admission" element={<Admission />} />
                <Route path="/about" element={<About />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/services" element={<Services />} />
                <Route path="/poster" element={<Poster />} />
                <Route path="/banner" element={<Banner />} />
                <Route path="/comics" element={<Comics />} />
                <Route path="/videos" element={<Videos />} />
                <Route path="/digital" element={<Digital />} />
                <Route path="/motion" element={<Motion />} />
                <Route path="/OfflineCourses" element={<OfflineCourses />} />
                <Route path="/OnlineCourses" element={<OnlineCourses />} />
                <Route path="/Workshop" element={<Workshop />} />
                <Route path="/ShortCourses" element={<ShortCourses />} />
                <Route path="/FeeStructure" element={<FeeStructure />} />
                <Route path="/Blogpost" element={<Blogpost />} />
                <Route path="/News" element={<News />} />
                <Route path="/Gallery" element={<Gallery />} />
                <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />

            </Routes>
        </Router>
    );
};

export default App;