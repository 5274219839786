import React from 'react';
import './Workshop.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import TopNavbar from '../Nav/TopNavbar'
import { useNavigate } from 'react-router-dom';

const Workshop = () => {
    const workshops = [
        { name: "Graphic Design", duration: "2 Week Course" },
        { name: "Motion Graphics", duration: "2 Week Course" },
        { name: "2D Animation", duration: "2 Week Course" },
        { name: "Video Editing",duration: "1 Week Course" },
        { name: "Storyboard and Storytelling",duration: "1 Week Course"  },
        { name: "Storyboarding Comic Art",duration: "1 Week Course"  },
        { name: "Digital Art",duration: "2 Week Course"  }
    ];
        const navigate = useNavigate();
    
        const handleNavigation = () => {
            navigate('/admission');
        };
    
    return (
        <>
            <TopNavbar />
            <div className="workshop_container">
                <h1 className="workshop_title">Workshop</h1>
                <div className="workshop_cards">
                    {workshops.map((workshop, index) => (
                        <div key={index} className="workshop_card" onClick={handleNavigation}>
                            <div className="workshop_header">COURSE {index + 1}</div>
                            <div className="workshop_info">
                                <h3 className="workshop_name">{workshop.name}</h3>
                                <p className="workshop_duration">{workshop.duration}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Workshop;
