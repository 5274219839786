import React from 'react'
import TopNavbar from '../Nav/TopNavbar'
import './Comics.css';
import Header from '../Header/Header.js'
import Footer from '../Footer/Footer.js';
import { DhyeyComicsget } from '../Api/CoreApi.js';
import { useEffect ,useState} from 'react';

function Comics() {
    const [cardsData, setcardsData] = useState([])
    useEffect(() => {
        dhyeyComicsget()

    }, [])
    const dhyeyComicsget = async () => {
        const response = await DhyeyComicsget();
        setcardsData(response)
    }
  
    return (
        <>
             <TopNavbar />
      <section className="gallery-section">
        <h1>Comics</h1>
        <div className="gallery-container">
          {cardsData.map((blog, index) => (
            <div className="gallery-card" key={index}>
              <img className="gallery-image" src={blog.picture} alt={`Gallery Image ${index + 1}`} />
            </div>
          ))}
        </div>
        <br/>
      </section>
      <Footer />

        </>
    )
}

export default Comics;

