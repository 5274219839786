import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../Images/1.png";
import Sidebar from "../Nav/Sidebar";
import Backdrop from "./Backdrop";
import BurgerIcon from "./BurgerIcon";
import { useNavigate } from "react-router-dom";
export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const [isMobile, setIsMobile] = useState(false);
  const [showInsightMenu, setShowInsightMenu] = useState(false); // State to control Insight submenu

  // Function to toggle Insight submenu
  const toggleInsightMenu = () => {
    setShowInsightMenu((prev) => !prev);
  };

  return (
    <div >
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "80px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          <div className="flexSpacediv">
            <img src={logo} alt="logo" width={80} />
            <h1 className="logo_name" style={{ fontSize: "40px"}} >Dhyey Academy</h1>
          </div>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper >

            <Link to="/" className="headerlink1" style={{ fontFamily: "Montserrat, sans-serif", color: "#970d99f0", fontWeight: "500", fontSize: "18px" }}>Home</Link>
            <Link to="/about" className="headerlink2" style={{ fontFamily: "Montserrat, sans-serif", color: "#970d99f0", fontWeight: "500", fontSize: "18px" }}>About</Link>
            <Link to="/courses" className="headerlink3" style={{ fontFamily: "Montserrat, sans-serif", color: "#970d99f0", fontWeight: "500", fontSize: "18px" }}>Courses</Link>
            <Link to="/services" className="headerlink4" style={{ fontFamily: "Montserrat, sans-serif", color: "#970d99f0", fontWeight: "500", fontSize: "18px" }}>Services</Link>
            <Link to="/admission" className="headerlink5" style={{ fontFamily: "Montserrat, sans-serif", color: "#970d99f0", fontWeight: "500", fontSize: "18px" }}>Admission</Link>
            <div className="insight-menu">
              <span onClick={toggleInsightMenu} className="insight-link">
                Insight
              </span>
              {/* Insight submenu */}
              {showInsightMenu && (
                <div className="insight-submenu">
                  <Link to="/Blogpost" className="headerlink6" style={{ fontFamily: "Montserrat, sans-serif", color: "#970d99f0" , fontWeight: "500", fontSize: "18px"}}>Blog</Link>
                  <Link to="/news" className="headerlink7" style={{ fontFamily: "Montserrat, sans-serif", color: "#970d99f0" , fontWeight: "500", fontSize: "18px"}}>News & Events</Link>
                </div>
              )}
            </div>
            <Link to="/contact" className="headerlink8" style={{ fontFamily: "Montserrat, sans-serif", color: "#970d99f0", fontWeight: "500", fontSize: "18px" }}>Contact</Link>

          </UlWrapper>
        </NavInner>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;

