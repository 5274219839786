import { message } from 'antd'
import API from './Api'

export const DhyeyComicsget = async () => {
    const response = await API.get(`api/dhyey/comics/`,
        { headers: { 'Content-Type': 'application/json'} }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const DhyeyBannerget = async () => {
    const response = await API.get(`api/dhyey/Banner/`,
        { headers: { 'Content-Type': 'application/json'} }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const DhyeyDigitalget = async () => {
    const response = await API.get(`api/dhyey/DigitalArt/`,
        { headers: { 'Content-Type': 'application/json'} }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const Dhyeymotionget = async () => {
    const response = await API.get(`api/dhyey/MotionGraphic/`,
        { headers: { 'Content-Type': 'application/json'} }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const Dhyeyposterget = async () => {
    const response = await API.get(`api/dhyey/Poster/`,
        { headers: { 'Content-Type': 'application/json'} }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const Dhyeyvideoget = async () => {
    const response = await API.get(`api/dhyey/video/`,
        { headers: { 'Content-Type': 'application/json'} }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const Dhyeyworkget = async () => {
    const response = await API.get(`api/dhyey/Work/`,
        { headers: { 'Content-Type': 'application/json'} }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

export const AdmissionPost = async (formData) => {
    const response = await API.post(`api/dhyey/admission/`,formData,
        { headers: { 'Content-Type': 'application/json'} }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}


export const ContactPost = async (formData) => {
    const response = await API.post(`api/dhyey/contact/`,formData,
        { headers: { 'Content-Type': 'application/json'} }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}


export const BlogGet = async () => {
    const response = await API.get(`api/dhyey/blog/`,
        { headers: { 'Content-Type': 'application/json'} }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const UpcomingAndLatesNewsGet = async () => {
    const response = await API.get(`api/dhyey/news/upcoming/`,
        { headers: { 'Content-Type': 'application/json'} }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}