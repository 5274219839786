import React, { useState,useEffect } from 'react'
import TopNavbar from '../Nav/TopNavbar'
import './Video.css';
import Header from '../Header/Header.js'
import Footer from '../Footer/Footer.js';
import { Dhyeyvideoget } from '../Api/CoreApi.js';
function Videos() {
   const [video,setVideo]=useState([])
   useEffect(() => {
    dhyeyBannerget()

}, [])
const dhyeyBannerget = async () => {
    const response = await Dhyeyvideoget();
    setVideo(response)
}
  
    return (
        <>
             <TopNavbar />
             <section className="gallery-section">
  <h1>Videos</h1>
  <div className="gallery-container">
    {video.map((blog, index) => (
      <div className="gallery-card" key={index}>
        <iframe  src={blog.youtube_link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    ))}
  </div>
  <br />
</section>

      <Footer />
       
        </>
    )
}

export default Videos;

