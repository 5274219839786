// TermsAndConditions.js
import React from 'react';
import './TermsAndConditions.css'; // Importing the CSS file
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import TopNavbar from '../Nav/TopNavbar'
const TermsAndConditions = () => {
    return (
        <div className="terms-and-conditions">
            <TopNavbar /><br></br>
            
                <h1 className='terms-container-h1'>TERMS AND CONDITIONS</h1>
                <div className="terms-container">
                    <section>
                        <h2 className='terms-container-h2'>1. Course Fee</h2>
                        <ul className='terms-container-paraaaaa'>
                            <li><strong>Refund Policy:</strong> Course fees are non-refundable once paid. If a student withdraws from a course, no portion of the fee will be reimbursed.</li>
                            <li><strong>Grace Period:</strong> Students are granted a three-day grace period from the due date mentioned in the receipt to make any pending payments. After this period, a late fee of Rs. 100 per day will be incurred.</li>
                            <li><strong>Termination of Candidacy:</strong> Failure to pay outstanding fees within fifteen days post-grace period will result in termination of candidacy from the course.</li>
                            <li><strong>Fee Updates:</strong> The Management reserves the right to update or modify course fees, rules, and regulations at its sole discretion.</li>
                            <li><strong>Schedule Changes:</strong> The Management is not liable for any changes in the schedule, postponement, or cancellation of lectures or lab sessions due to unforeseen circumstances.</li>
                        </ul>
                    </section>
                    < section>
                        <h2 className='terms-container-h2'>2. Conduct at the Academy</h2>
                        <ul className='terms-container-paraaaaa'>
                            <li><strong>Discipline:</strong> Students must adhere to a high standard of discipline while on Academy premises. Disruptive behavior or harm to others will not be tolerated.</li>
                            <li><strong>Noise and Distractions:</strong> Noisy instruments or disruptive activities are prohibited in classrooms and labs.</li>
                            <li><strong>Prohibited Items:</strong> Smoking is strictly prohibited. Students are also not allowed to carry or use laptop computers, pen drives, or other electronic devices unless expressly permitted.</li>
                            <li><strong>Identity Card:</strong> Students must carry their Identity Card at all times and present it upon request by any staff member. Lost Identity Cards must be reported immediately, and a replacement will be issued upon receipt of a written request and payment of any applicable fees.</li>
                            <li><strong>Dress Code:</strong> A dress code may be enforced. Students are expected to dress appropriately for a professional learning environment.</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className='terms-container-h2'>3. Discipline</h2>
                        <ul className='terms-container-paraaaaa'>
                            <li><strong>Extra Practice Time:</strong> Students wishing to schedule machines for extra practice must coordinate with their faculty at least three days in advance. Machines are available only when not in use for regular classes or sessions.</li>
                            <li><strong>Accountability:</strong> Students are responsible for any damage or loss to academy equipment or property.</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className='terms-container-h2'>4. Attendance and Participation</h2>
                        <ul className='terms-container-paraaaaa'>
                            <li><strong>Attendance Requirements:</strong> Regular attendance is mandatory. Excessive absences may impact a student's ability to complete the course and may result in penalties.</li>
                            <li><strong>Participation:</strong> Active participation in classes and lab sessions is expected. Students should come prepared and engage in all assignments and projects.</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className='terms-container-h2'>5. Intellectual Property</h2>
                        <ul className='terms-container-paraaaaa'>
                            <li><strong>Content Use:</strong> All course materials, including lecture notes, assignments, and software, are the intellectual property of Dhyey Academy. Unauthorized use or distribution of these materials is prohibited.</li>
                            <li><strong>Student Work:</strong> Students retain ownership of their individual work but grant Dhyey Academy permission to use it for promotional purposes with proper attribution.</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className='terms-container-h2'>6. Code of Ethics</h2>
                        <ul className='terms-container-paraaaaa'>
                            <li><strong>Academic Integrity:</strong> Students are expected to uphold the highest standards of academic integrity. Plagiarism, cheating, or any form of dishonesty will result in disciplinary action.</li>
                            <li><strong>Respect:</strong> All students, faculty, and staff should be treated with respect. Harassment or discrimination will not be tolerated.</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className='terms-container-h2'>7. Liability and Safety</h2>
                        <ul className='terms-container-paraaaaa'>
                            <li><strong>Personal Property:</strong> The Academy is not responsible for any loss or damage to personal property while on its premises.</li>
                            <li><strong>Health and Safety:</strong> Students must adhere to all health and safety regulations as outlined by the Academy. This includes following any emergency procedures and reporting any safety concerns immediately.</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className='terms-container-h2'>8. Amendments</h2>
                        <ul className='terms-container-paraaaaa'>
                            <li><strong>Terms Updates:</strong> Dhyey Academy reserves the right to update these Terms and Conditions at any time. Students will be notified of any significant changes and are encouraged to review the terms periodically.</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className='terms-container-h2'>9. Contact Information</h2>
                        <ul className='terms-container-paraaaaa'>
                            <li><strong>Inquiries:</strong> For any questions or clarifications regarding these Terms and Conditions, please contact the Academy’s administration office at support@dhyeyacademy.co.in or +91-7024124442.</li>
                        </ul>
                    </section>
                </div><br></br>
            
            <Footer />
            </div>
    );
};

export default TermsAndConditions;