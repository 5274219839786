import React, { useState } from 'react';
import './AdmissionForm.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Select } from 'antd';
import { AdmissionPost } from '../Api/CoreApi';
import TopNavbar from '../Nav/TopNavbar'
const { Option } = Select;

const AdmissionForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        email: '',
        dob: '',
        contact_no: '',
        preferred_date: '',
        statement_purpose: '',
        career_goals: '',
        course_preferences: [],
        additional_Information: '',
        declaration: false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: checked,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleCourseChange = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            course_preferences: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        try {
            const response = await AdmissionPost(formData);
            if (response.msg === 'Admission Successfully') {
                // Reset form data
                setFormData({
                    name: '',
                    address: '',
                    email: '',
                    dob: '',
                    contact_no: '',
                    preferred_date: '',
                    statement_purpose: '',
                    career_goals: '',
                    course_preferences: [],
                    additional_Information: '',
                    declaration: false,
                });
                alert('Admission submitted successfully!'); // Optional success message
            } else {
                alert('Submission failed. Please try again.'); // Handle error message
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while submitting the form.'); // Handle network error
        }
    };
    
    return (
        <>
            <TopNavbar />
            <div className="admission-form-container">
                <h1 className='admission_h1'>Admission Form</h1>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="a_form_div">
                        <div className="form_content" >
                            <div className='div1_form'>
                                <label className="label">
                                    Full Name: 
                                    </label>
                                    <input type="text" name="name" className="input" onChange={handleChange} 
                                    value={formData.name}
                                    required />
                               
                                <label className="label">
                                    Address:
                                    </label>
                                    <input type="text" name="address" className="input" onChange={handleChange} 
                                    value={formData.address}
                                    required/>
                  
                                <label className="label">
                                    E-mail:
                                    </label>
                                    <input type="email" name="email" className="input" onChange={handleChange} 
                                    value={formData.email}
                                    required />
                                
                                <label className="label">
                                    Date of Birth:
                                    </label>
                                    <input type="date" name="dob" className="input" onChange={handleChange} 
                                    value={formData.dob}/>
                            
                                <label className="label">
                                    Phone:
                                    </label>
                                    <input type="tel" name="contact_no" className="input" onChange={handleChange} 
                                    value={formData.contact_no}
                                    required/>
                                
                                <label className="label">
                                    Preferred Start Date:
                                    </label>
                                    <input type="date" name="preferred_date" className="input" onChange={handleChange} value={formData.preferred_date}required />
                                
                            </div>
                            <div className='div2_form'>
                                <div className="label">
                                 
                                    <label className="label">
                                    Course Preferences:
                                    </label><br/>
                                    <Select
                                        mode="multiple"
                                        placeholder="Select courses"
                                        style={{ width: '100%' }}
                                        className="select_admission"
                                        onChange={handleCourseChange}
                                        required
                                        value={formData.course_preferences}
                                    >
                                        <Option value="Graphic Design" style={{color:"purple"}}>Graphic Design</Option>
                                        <Option value="Brand or Logo Identity Design" style={{color:"purple"}}>Brand or Logo Identity Design</Option>
                                        <Option value="Motion Graphics" style={{color:"purple"}}>Motion Graphics</Option>
                                        <Option style={{color:"purple"}} value="Video Editing">Video Editing</Option>
                                        <Option style={{color:"purple"}} value="2D Animation">2D Animation</Option>
                                        <Option style={{color:"purple"}} value="Background Design">Background Design</Option>
                                        <Option style={{color:"purple"}} value="3D Modeling">3D Modeling</Option>
                                        <Option style={{color:"purple"}} value="Adobe Photoshop">Adobe Photoshop</Option>
                                        <Option style={{color:"purple"}} value="Adobe Illustrator">Adobe Illustrator</Option>
                                        <Option style={{color:"purple"}} value="Adobe Animate">Adobe Animate</Option>
                                        <Option style={{color:"purple"}} value="Adobe After Effects">Adobe After Effects</Option>
                                        <Option style={{color:"purple"}} value="Adobe Premiere Pro">Adobe Premiere Pro</Option>
                                        <Option style={{color:"purple"}} value="Adobe InDesign">Adobe InDesign</Option>
                                        <Option style={{color:"purple"}} value="Adobe Audition">Adobe Audition</Option>
                                        <Option style={{color:"purple"}} value="Adobe Fresco">Adobe Fresco</Option>
                                        <Option style={{color:"purple"}} value="Corel Draw">Corel Draw</Option>
                                        <Option style={{color:"purple"}} value="Tohma2D">Tohma2D</Option>
                                        <Option style={{color:"purple"}} value="Blender">Blender</Option>
                                        <Option style={{color:"purple"}} value="Toon Boom Harmony">Toon Boom Harmony</Option>
                                        <Option style={{color:"purple"}} value="Autodesk Maya">Autodesk Maya</Option>
                                        <Option style={{color:"purple"}} value="3ds Max">3ds Max</Option>
                                    </Select>
                                </div>
                                <label className="label">
                                    Statement of Purpose:
                                    </label>
                                    <textarea name="statement_purpose" className="textarea" placeholder="Why do you want to study Graphic Design and Animation?" value={formData.statement_purpose} onChange={handleChange} required></textarea>
                                
                                <label className="label">
                                    Career Goals:
                                    </label>
                                    <textarea name="career_goals" className="textarea" onChange={handleChange} required value={formData.career_goals}></textarea>
                                
                                <label className="label">
                                    Additional Information:
                                    </label>
                                    <textarea name="additional_Information" className="textarea" onChange={handleChange} required value={formData.additional_Information}></textarea>
                                
                              <br/>
                                    <input type="checkbox" name="declaration" className="checkbox" onChange={handleChange} required value={formData.declaration}/>
                                    <span 
                                    style={{color:'#0f7272e7',fontWeight:"bold",fontSize:"16px"}}
                                    >
                                    &nbsp; I certify that the information provided above is true and accurate to the best of my knowledge.
                                    </span>
                                   
                                <br/>
                                <button type="submit" className="submit-btn">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Footer />
        </>
    );
};

export default AdmissionForm;
