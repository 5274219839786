import React, { useState,useEffect } from 'react';
import './News&Events.css'; // Import the CSS file
import Footer from '../Footer/Footer';
import TopNavbar from '../Nav/TopNavbar'
import { UpcomingAndLatesNewsGet } from '../Api/CoreApi';
const NewsEvents = () => {
  const [eventsData, seteventsData] = useState([])
  const [newsData,setNewsData]=useState([])
  useEffect(() => {
    upcomingAndLatesNewsGet()

  }, [])
  const upcomingAndLatesNewsGet = async () => {
    const response = await UpcomingAndLatesNewsGet();
    if(response){
    seteventsData(response.event)
    setNewsData(response.news)
    }
  }
  return (
    <>
    <TopNavbar />
    <div>
         <div className='news-events-mainpage'>
    <div className="news-events-page">
      <h1 className="news-events-title">News & Events</h1>

      <section className="news-section">
        <h2>Latest News</h2><br></br>
        <div className="news-cards">
          {newsData.map((news) => (
            <a href={news.link} target='_blank' style={{textDecoration:"none"}}>
            <div className="news-card" key={news.id}>
              <img src={news.picture} alt={news.title} className="news-image" />
              <div className="news-content">
                <h3>{news.title}</h3>
                <p className="news-date">{news.date}</p>
                <p>{news.description}</p>
              </div>
            </div>
            </a>
          ))}
        </div>
      </section>

      <section className="events-section">
        <h2>Upcoming Events</h2><br></br>
        <ul className="events-list">
          {eventsData.map((event) => (
            <li key={event.id} className="event-item">
              <h3>{event.title}</h3>
              <p className="event-date">{event.date}</p>
              <p className="event-location">{event.address}</p>
            </li>
          ))}
        </ul>
      </section>
    </div>
    </div>
    </div>
    <Footer />
    </>
  );
};

export default NewsEvents;
