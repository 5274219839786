import React from 'react';
import './Online_courses.css';
import { Collapse } from 'antd'; 
// import course_detail_pdf from './COURSE DETAILS.pdf';
import GraphicDesignpdf from './Graphic Designer complete guide.pdf'
import MotionGraphicpdf from './Motion Designer Guide.pdf'
import DigitalArtpdf from './digital artist complete guide.pdf'
import VideoEditingpdf from './GUIDE TO BECAME VIDEO EDITOR.pdf'
import ComicArtpdf from './comic art complete guide.pdf'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import TopNavbar from '../Nav/TopNavbar'
const { Panel } = Collapse; // Destructure Panel from Collapse

const Online_courses = () => {
    const OnlineCourse = [
        {
            title: "Graphic Design",
            beginnerItems: ["Adobe Photoshop Basics", "Adobe Illustrator Basics"],
            advancedItems: ["Advanced Adobe Photoshop", "Advanced Adobe Illustrator", "Advanced Adobe InDesign","Corel Draw"],
            pdfLink: GraphicDesignpdf
        },
        {
            title: "Motion Graphic",
            beginnerItems: ["Adobe Photoshop Basics", "Adobe Illustrator Basics", "Adobe After Effects Basics"],
            advancedItems: ["Advanced Adobe Photoshop", "Advanced Adobe Illustrator", "Advanced Adobe After Effects", "Advanced Adobe Premiere Pro"],
            pdfLink: MotionGraphicpdf
        },
        {
            title: "Digital Art",
            beginnerItems: ["Adobe Photoshop Basics", "Digital Pen Tablet"],
            advancedItems: ["Advanced Adobe Photoshop", "Advanced Adobe Illustrator","Advanced Adobe Fresco", "Advanced Digital Pen Tablet"],
            pdfLink: DigitalArtpdf
        },
        {
            title: "Video Editing",
            beginnerItems: ["Basic Video Editing with Premiere Pro"],
            advancedItems: ["Advanced Adobe Photoshop","Advanced Adobe Premiere Pro","Advanced Adobe After Effects", "Advanced Adobe Audition",],
            pdfLink: VideoEditingpdf
        },
        {
            title: "Comic Art",
            beginnerItems: ["Adobe Photoshop Basics","Storyboard Basics", "Digital Pen Tablet"],
            advancedItems: ["Advanced Adobe Fresco","Advanced Adobe Photoshop","Advanced Adobe Illustrator", "Advanced Storyboarding", "Advanced Digital Pen Tablet"],
            pdfLink: ComicArtpdf
        }
    ];

    // Function to handle PDF download
    const handleDownload = (pdfLink, title) => {
        const link = document.createElement('a');
        link.href = pdfLink;
        link.setAttribute('download', `${title}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <TopNavbar />
            <div className="Online_course_container">
                <h1 className='Online_courses_title'>Online Courses</h1>
                {OnlineCourse.map((onlinecourse, index) => (
                    <div className="Online_course_card" key={index}>
                        <div className="Online_course_card1">
                            <h3 className="Online-title">{onlinecourse.title}</h3>
                        </div>
                        <Collapse accordion>
                            <Panel header="Beginner" key="1">
                                <ul className="Online-items">
                                    {onlinecourse.beginnerItems.map((item, idx) => (
                                        <li key={idx}>{item}</li>
                                    ))}
                                </ul>
                            </Panel>
                            <Panel header="Advanced" key="2">
                                <ul className="Online-items">
                                    {onlinecourse.advancedItems.map((item, idx) => (
                                        <li key={idx}>{item}</li>
                                    ))}
                                </ul>
                            </Panel>
                        </Collapse>
                        {/* Button to trigger PDF download */}
                        <button
                            className="download-pdf-button-course"
                            onClick={() => handleDownload(onlinecourse.pdfLink, onlinecourse.title)}
                        >
                            <svg
                                className="mysvg"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                height="24px"
                                width="24px"
                            >
                                <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                                <g
                                    stroke-linejoin="round"
                                    stroke-linecap="round"
                                    id="SVGRepo_tracerCarrier"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                    <g id="Interface / Download">
                                        <path
                                            stroke-linejoin="round"
                                            stroke-linecap="round"
                                            stroke-width="2"
                                            stroke="#f1f1f1"
                                            d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12"
                                            id="Vector"
                                        ></path>
                                    </g>
                                </g>
                            </svg>
                            <span className="texto">Download</span>
                        </button>
                    </div>
                ))}
            </div>
            <Footer />
        </>
    );
};

export default Online_courses;
