import React from 'react';
import { Card, Row, Col } from 'antd'; // Ant Design components
import './ShortTerm_courses.css';
import icon1 from './Image/adobe_photoshop.png';
import icon2 from './Image/Adobe_Illustrator.png';
import icon3 from './Image/adobe_animate.png';
import icon4 from './Image/adobe_after_effect.png';
import icon5 from './Image/adobe_premierpro.png';
import icon01 from './Image/indesign.png'
import icon02 from './Image/adobe-audition.png'
import icon03 from './Image/adobe-fresco.png'
import icon04 from './Image/Tohma2D.svg'
import icon6 from './Image/coral_draw_official.png';
import icon7 from './Image/Autodesk-maya.png';
import icon8 from './Image/toon-boom-logo.svg';
import icon10 from './Image/3ds_max.png';
import icon11 from './Image/blender.png';
// import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import TopNavbar from '../Nav/TopNavbar'
const { Meta } = Card;

const ShortTerm_courses = () => {
    const courses = [
        { name: "Adobe Photoshop", icon: icon1, description: "Learn advanced image editing with Photoshop." },
        { name: "Adobe Illustrator", icon: icon2, description: "Master vector art and graphic design." },
        { name: "Adobe Animate", icon: icon3, description: "Create stunning animations with Animate." },
        { name: "Adobe After Effects", icon: icon4, description: "Specialize in motion graphics and visual effects." },
        { name: "Adobe Premiere Pro", icon: icon5, description: "Edit videos like a pro with Premiere Pro." },
        { name: "Adobe InDesign", icon: icon01, description: "Create stunning layouts for print and digital media with InDesign." },
        { name: "Adobe Audition", icon: icon02, description: "Record, edit, and mix audio for professional-quality soundtracks." },
        { name: "Adobe Fresco", icon: icon03, description: "Draw and paint naturally with Adobe Fresco's real-world brushes." },
        { name: "Corel Draw", icon: icon6, description: "Design with precision using Corel Draw." },
        { name: "Tohma2D", icon: icon04, description: "Create 2D animations with Tohma2D for professional projects." },      
        { name: "Blender", icon: icon11, description: "Learn 3D modeling, animation, and rendering." },
        { name: "Toon Boom Harmony", icon: icon8, description: "Animate your ideas with Toon Boom." },
        { name: "Autodesk Maya", icon: icon7, description: "Dive into 3D modeling and animation." },
        { name: "3ds Max", icon: icon10, description: "Create 3D content with 3ds Max." },
    ];

    return (
        <>
            <TopNavbar />
            <div className="shortterm_courses_container">
                <h1 className="courses_title">Short Term Courses</h1>
                <div className='shortterm_courses_div'>
                    <Row gutter={[16, 16]} justify="center">
                        {courses.map((course, index) => (
                            <Col key={index} xs={24} sm={12} md={8} lg={6}>
                                <Card className="course_card">
                                    <div className="course_content_container">
                                        <div className="course_image">
                                            <img alt={course.name} src={course.icon} className="course_icon" />
                                        </div>
                                        <div className="course_details">
                                            <h3>{course.name}</h3>
                                            <p>{course.description}</p>
                                            <Link to='/admission' ><button className="button2">Enroll Now</button></Link>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default ShortTerm_courses;
