


import React from 'react';
import heart from '../Images/heartimg2.png';
import './HeartAnimation.css';

const HeartAnimation = () => {
    return (
        <div className="heart-container">
            <img src={heart} alt="Heart" className="heart" />
        </div>
    );
};

export default HeartAnimation;
