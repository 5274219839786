import React from 'react'
import TopNavbar from '../Nav/TopNavbar'
import '../Gallery/Gallery.css'
import Header from '../Header/Header.js'
import Footer from '../Footer/Footer.js';
import { Dhyeymotionget } from '../Api/CoreApi.js';
import { useEffect, useState } from 'react';
function Motion() {
  const [cardsData, setcardsData] = useState([])
  useEffect(() => {
    dhyeymotionget()

  }, [])
  const dhyeymotionget = async () => {
    const response = await Dhyeymotionget();
    setcardsData(response)
  }

  return (
    <>
      <TopNavbar />
      <section className="gallery-section">
        <h1>Motion Graphics</h1>
        <div className="gallery-container">
          {cardsData.map((blog, index) => (
            <div className="gallery-card" key={index}>
              <img className="gallery-image" src={blog.picture} alt={`Gallery Image ${index + 1}`} />
            </div>
          ))}
        </div>
        <br />
      </section>
      <Footer />
    </>
  )
}

export default Motion;


