// Blog.js
import React,{useState,useEffect} from 'react';
import './blogpost.css';
import Footer from '../Footer/Footer';
import TopNavbar from '../Nav/TopNavbar'
import { BlogGet } from '../Api/CoreApi';
const Blog = () => {
  const [blogs, setblogs] = useState([])
  useEffect(() => {
    blogGet()

  }, [])
  const blogGet = async () => {
    const response = await BlogGet();
    setblogs(response)
  }
  return (
    <>
      <TopNavbar />
      <section className="blog-section">
        <h1>Our Blog</h1>
        <div className="blog-container">
          {blogs.map((blog, index) => (
            <div className="blog-card" key={index}>
              <img className="blog-image" src={blog.picture} alt={blog.title} />
              <h2>
                <a href={blog.link} target='_blank'>{blog.title}</a>
              </h2>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Blog;
