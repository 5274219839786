import React, { useState } from 'react';
import './Contact_us.css';
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Header/Header.js'
import Footer from '../Footer/Footer.js';
import { ContactPost } from '../Api/CoreApi.js';
import TopNavbar from '../Nav/TopNavbar'
const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact_no: '',
    message: ''
  });
  const [loading, setLoading] = useState(false)
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    const response = await ContactPost(formData)
    if (response.msg === 'Contact Successfully') {
      setLoading(false)
      setFormData({
        name: '',
        email: '',
        contact_no: '',
        message: ''
      })
      toast.success('Thank You We Will Contact You Very Soon')
    } else {
      alert('')
    }


  };

  return (
    <>
      <TopNavbar />
    
        <div className="dcontent_container">
          <div className="dcontact-wrapper">
            <div className="dcontact-us">
              <div className='dcontacthead'>
                <h1 className='dcontact_h1'>CONTACT US</h1>
                <p className='dcontact_p'>If you have any questions or need support, feel free to reach out to us through our contact form or support page.</p>
              </div>
              {/* <div className="contact-info-wrapper"> */}
              <div className="dcontact-info">
                <h2 contact_h2>Contact Us For More Info</h2>
                <div className='address-div'>
                  <p><FaMapMarkerAlt /> &nbsp;&nbsp;Near Agresan Collage, Purani Basti,Raipur, Chhattisgarh 492001.</p>
                  <p><FaPhone /> &nbsp;&nbsp;+91-7024124442</p>
                  <p><FaEnvelope /> &nbsp;&nbsp; info@dhyeyacademy.co.in</p>
                </div>
              </div>
              {/* </div> */}
            </div>
            <div className="dcontact-form">


              {/* <h1>Drop Your Query</h1> */}
              <form onSubmit={handleSubmit}>
                <div className="dcontact-input-group">
                  <br></br>
                  <label htmlFor="name">Your Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="dcontact-input-group">
                  <label htmlFor="email">Your Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="dcontact-input-group">
                  <label htmlFor="number">Your Number</label>
                  <input
                    type="text"
                    id="number"
                    name="contact_no"
                    placeholder="Enter Your 10 digit mobile Number"
                    value={formData.contact_no}
                    onChange={handleChange}
                    required
                    maxLength={10}
                    minLength={10}
                  />
                </div>
                <div className="dcontact-input-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Please describe your Query in detail."

                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </div>

                <button type="submit" className="dcontact-button" disabled={loading}>
                  {loading ? 'Sending...' : 'Submit'}
                </button>
              </form>
            </div>
          </div>
          <div className="dlocation">
            <h1>OUR LOCATION</h1>
            <div className="dmap-container">
              <iframe className='dlocations_maping'
                title="location-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2832.9089467921785!2d81.62338585145848!3d21.22784409368442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28ddc819881b2b%3A0x8a55ea8a3bb3220e!2shari%20om%20kirana%20and%20general%20store!5e1!3m2!1sen!2sin!4v1721298724346!5m2!1sen!2sin"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      
      {/* <br />    <br />    <br />    <br />    <br />    <br />    <br />    <br />    <br /> */}
      <Footer />
    </>
  );
};

export default ContactUs;

