import React from 'react';
import './Courses.css';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import Img3 from '../Images/coursesimg3.webp';
import Img4 from '../Images/coursesimg4.webp';
import Img5 from '../Images/Coursesimg5.webp';
import Img6 from '../Images/Coursesimg6.webp';
// import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import TopNavbar from '../Nav/TopNavbar'
function Courses() {
    return (
        <div>
            <TopNavbar />
            <div className='courses_background_color'>
                <h1 className='heading_courses'>Our Courses</h1>
                <div className='courses_images_main'>
                    <div className='courses_images_div1'>
                        <img src={Img6} className='courseimg1'></img>
                        <Link to='/OfflineCourses' style={{textDecoration:'none'}}><p className='courses_paragraph'>Offline Courses</p>
                            <ArrowRightOutlined className='arrowicons' /></Link>
                    </div>
                    <div className='courses_images_div1'>
                        <img src={Img4} className='courseimg1'></img>
                        <Link to='/OnlineCourses' style={{textDecoration:'none'}}><p className='courses_paragraph'>Online Courses</p>
                            <ArrowRightOutlined className='arrowicons' /></Link>
                    </div>
                    <div className='courses_images_div1'>
                        <img src={Img5} className='courseimg1'></img>
                        <Link to='/Workshop' style={{textDecoration:'none'}}><p className='courses_paragraph'>Workshop</p>
                            <ArrowRightOutlined className='arrowicons' /></Link>
                    </div>
                    <div className='courses_images_div1'>
                        <img src={Img3} className='courseimg1'></img>
                        <Link to="/ShortCourses" style={{textDecoration:'none'}}><p className='courses_paragraph'>Short Courses</p>
                            <ArrowRightOutlined className='arrowicons' /></Link>
                    </div>
                </div>
                <div className='button_div1'><Link to="/FeeStructure"><button type="submit" className="submit-btn2">Fee Structure</button></Link></div>
            </div>

            <Footer />
        </div>
    )
}

export default Courses

